

interface Result {
    valid: boolean;
    reason?: string;
}

export function isValidFilenameSharepoint(filename: string): Result {
    const invalidChars = /[\\/:*?"<>|]/;
    if (invalidChars.test(filename)) {
        return {
            valid: false,
            reason: 'Contains invalid characters'
        };
    }

    if (filename.endsWith('.') || filename.endsWith(' ')) {
        return {
            valid: false,
            reason: 'Cannot end with a period or space'
        }
    }

    if (filename.length > 240) {
        return {
            valid: false,
            reason: 'Name too long',
        }
    }

    if (filename.length === 0) {
        return {
            valid: false,
            reason: 'Name cannot be empty',
        }
    }

    return {
        valid: true,
    }
}
