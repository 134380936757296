import NavBar from '../../components/navBar';
import { PageTitle } from '../../components/pageTitle';
import CustomEquipments from './customEquipments';
import Customers from './customers';
import Operators from './operators';


export default function Resources() {
    return (
        <>
            <PageTitle title='Resources' />
            <NavBar />
            <div
                className='content'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    marginTop: '0px',
                    paddingBottom: '20px',
                }}
            >
                <Operators />
                <CustomEquipments />
                <Customers />
            </div>

        </>
    );
}
