import { useState } from 'react';
import { Link } from 'react-router-dom';
import LinkButton from '../../components/linkButton';
import { Protocol } from '../../types/sharedTypeImpl';
import { formatDatetime } from '../../utils/fieldFormats';
import ProtocolContextMenu, { ProtocolContextMenuState } from './protocolContextMenu';


interface ProtocolTableProps {
    protocols: Protocol[] | undefined;
    onProtocolSetDisabled: (protocol: Protocol, isEnabled: boolean) => void;
    onProtocolCopy: (protocol: Protocol) => void;
    onSearchTextChange: (searchText: string) => void;
}

export default function ProtocolTable({
    protocols,
    onProtocolSetDisabled,
    onProtocolCopy,
    onSearchTextChange,
}: ProtocolTableProps) {
    const [menuProps, setMenuProps] = useState<ProtocolContextMenuState | undefined>(undefined);

    function handleMenuOpen(e: React.MouseEvent, protocol: Protocol) {
        e.preventDefault();
        setMenuProps({ protocol, screenCoords: { x: e.clientX, y: e.clientY } });
    }


    return (
        <>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Part number</th>
                        <th>Program name</th>
                        <th style={{ minWidth: '85px' }}>Positions</th>
                        <th style={{ minWidth: '110px' }}>Version</th>
                        <th style={{ width: '220px'}}>Customer</th>
                        <th style={{ minWidth: '160px' }}>Create time</th>
                        <th style={{ minWidth: '70px' }}>Status</th>
                        <th style={{ minWidth: '50px' }} />
                        <th style={{ minWidth: '110px' }} />
                        <th style={{ minWidth: '70px' }} />
                    </tr>
                </thead>
                <tbody>
                    {protocols?.map(it => {

                        return <tr key={it._id} className={getRowClass(it)}>
                            <td><CellWithSearchLink text={it.partDisplayName} onSearchTextChange={onSearchTextChange} /></td>
                            <td><CellWithSearchLink text={it.programs?.[0] ?? ''} onSearchTextChange={onSearchTextChange} /></td>
                            <td>{it.positions?.length ?? ''}</td>
                            <td>{it.version}</td>
                            <td className='ellipsize'>{it.customer}</td>
                            <td>{formatDatetime(it.createTime)}</td>
                            <td><Status protocol={it} /></td>
                            <td><Link to={`/protocol-view?id=${it._id}&mode=view`} className='a__button'>View</Link></td>
                            <td><EditButton protocol={it} /></td>
                            <td><LinkButton onClick={(e) => { handleMenuOpen(e, it) }} >Options</LinkButton></td>
                        </tr >
                    })}
                </tbody>
            </table >

            {menuProps && <ProtocolContextMenu
                protocol={menuProps.protocol}
                screenCoords={menuProps.screenCoords}
                onEnableSelected={(protocol) => onProtocolSetDisabled(protocol, false)}
                onDisableSelected={(protocol) => onProtocolSetDisabled(protocol, true)}
                onCopySelected={onProtocolCopy}
                onClose={() => setMenuProps(undefined)}
            />}
        </>
    );
}


function CellWithSearchLink({
    text,
    onSearchTextChange
}: { text: string, onSearchTextChange: (searchText: string) => void }) {
    return (
        <span className='link-button--non-intrusive' onClick={() => onSearchTextChange(text)}>
            {text}
        </span>
    );
}

function getRowClass(protocol: Protocol): string | undefined {
    return protocol.status === 'rejected' ? 'highlight--issue'
        : protocol.status === 'review' ? 'highlight--selected'
            : undefined;
}

function Status({ protocol }: { protocol: Protocol }) {
    const statusText = protocol.status === 'draft' ? 'Draft'
        : protocol.status === 'rejected' ? 'Rejected'
            : protocol.status === 'review' ? 'Review'
                : null;
    const disabledText = protocol.isDisabled ? 'Disabled' : null;
    const finalText = [statusText, disabledText].filter(it => it).join(', ');

    return <>{finalText}</>;
}

function EditButton({ protocol }: { protocol: Protocol }) {
    const continueDraft = protocol.status === 'draft' || protocol.status === 'rejected';
    const reviewProtocol = protocol.status === 'review';

    return <>{
        continueDraft ? <Link to={`/protocol-view?id=${protocol._id}&mode=edit`} className='a__button'>Continue</Link>
            : reviewProtocol ? <Link to={`/protocol-view?id=${protocol._id}&mode=review`} className='a__button'>Review</Link>
                : <Link to={`/protocol-view?baseProtocolId=${protocol._id}&mode=edit`} className='a__button'>New revision</Link>
    }</>;
}
