import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Equipment } from '../../../shared/types/equipment';
import { fetchCustomEquipments } from '../../api/requests';
import CustomEquipmentDialog from './customEquipmentDialog';
import CustomEquipmentsTable from './customEquipmentsTable';


export default function CustomEquipments() {

    const [isEquipmentDialogOpen, setIsEquipmentDialogOpen] = useState(false);

    const equipmentsQuery = useQuery({
        queryKey: ['custom-equipments'],
        queryFn: fetchCustomEquipments,
        select: (equipments) => [...equipments].sort((a, b) => a.displayName.localeCompare(b.displayName)),
    });
    const equipments = equipmentsQuery.data;


    const handleAddEquipmentClick = () => {
        setIsEquipmentDialogOpen(true);
    }

    return (<>
        <div>
            <h2>Custom equipments</h2>
            <button onClick={handleAddEquipmentClick}>Add equipment</button>
            {equipmentsQuery.isLoading && <div>Loading...</div>}
            {equipmentsQuery.error && <div>Error retrieving data</div>}
            {equipments && <CustomEquipmentsTable
                style={{ width: '800px' }}
                equipments={equipments}
            />}
        </div>

        {(isEquipmentDialogOpen && equipments) && <CustomEquipmentDialog
            equipments={equipments}
            onEquipmentAdded={(equipment: Equipment) => equipmentsQuery.refetch()}
            onClose={() => { setIsEquipmentDialogOpen(false) }}
        />}
    </>);
}
