import { useState } from 'react';
import { Customer } from '../../../shared/types/customer';
import ModalSubmitDialog from '../../components/modalSubmitDialog';
import { trpc } from '../../utils/trpc';


interface CustomerDialogProps {
    customers: Customer[];
    onCustomerAdded: (customer: Customer) => void;
    onClose: () => void;
}

export default function CustomerDialog({
    customers,
    onCustomerAdded,
    onClose,
}: CustomerDialogProps) {

    const [name, setName] = useState<string>('');

    const addCustomer = trpc.customers.add.useMutation();


    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    async function handleSubmit() {
        const customer: Customer = {
            isActive: true,
            name,
        };
        if (customers.some(it => it.name.toUpperCase() === customer.name.toUpperCase())) {
            alert('Customer name must be unique');
            return;
        }

        await addCustomer.mutateAsync(customer);

        onCustomerAdded(customer);
        onClose();
    }

    return (
        <ModalSubmitDialog
            frameStyle={{ width: '360px' }}
            isOpen={true}
            onCancel={onClose}
            onSubmit={handleSubmit}
        >
            <div>
                <div>
                    <div>Name</div>
                    <input
                        className='dialog-form__input'
                        type='text'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
        </ModalSubmitDialog>
    );
}
