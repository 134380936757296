import { useState } from 'react';
import { Customer } from '../../../shared/types/customer';
import Button from '../../components/button';
import { trpc } from '../../utils/trpc';
import CustomerDialog from './customerDialog';
import CustomersTable from './customersTable';


export default function Customers() {
    const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);

    const { data: customers, isLoading, error, refetch } = trpc.customers.get.useQuery();
    const customerSetActive = trpc.customers.setActive.useMutation({
        onSuccess: () => refetch(),
    });


    return (<>
        <div>
            <h2>Customers</h2>
            <Button
                onClick={() => setIsCustomerDialogOpen(true)}
                content='Add customer'
            />
            {isLoading && <div>Loading...</div>}
            {error && <div>Error retrieving data</div>}
            {customers && <CustomersTable
                style={{ width: '800px' }}
                customers={customers}
                onIsActiveChange={(name: string, isActive: boolean) => customerSetActive.mutate({ name, isActive })}
            />}
        </div>

        {(isCustomerDialogOpen && customers) && <CustomerDialog
            customers={customers}
            onCustomerAdded={(customer: Customer) => refetch()}
            onClose={() => setIsCustomerDialogOpen(false)}
        />}
    </>)
}
