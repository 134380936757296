

interface ButtonProps {
    className?: string;
    style?: React.CSSProperties;
    content: React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export default function Button({
    className,
    style,
    content,
    onClick,
    disabled,
    type,
}: ButtonProps) {
    return (
        <button
            className={className}
            style={style}
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {content}
        </button>
    )
}