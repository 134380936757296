import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchOperators, putOperator } from '../../api/requests';
import { Operator } from '../../types/sharedTypeImpl';
import OperatorDialog from './operatorDialog';
import OperatorsTable from './operatorsTable';


export default function Operators() {
    const [isOperatorDialogOpen, setIsOperatorDialogOpen] = useState(false);

    const operatorsQuery = useQuery({
        queryKey: ['operators'],
        queryFn: fetchOperators,
        select: (operators) => [...operators].sort((a, b) => a.fullName.localeCompare(b.fullName)),
    });
    const operators = operatorsQuery.data;


    const handleAddOperatorClick = () => {
        setIsOperatorDialogOpen(true);
    }

    const handleOperatorIsActiveChange = async (operator: Operator, isActive: boolean) => {
        await putOperator({ ...operator, isActive });
        operatorsQuery.refetch();
    }

    return (<>
        <div>
            <h2>Operators</h2>
            <button onClick={handleAddOperatorClick}>Add operator</button>
            {operatorsQuery.isLoading && <div>Loading...</div>}
            {operatorsQuery.error && <div>Error retrieving data</div>}
            {operators && <OperatorsTable
                style={{ width: '800px' }}
                operators={operators}
                onIsActiveChange={handleOperatorIsActiveChange}
            />}
        </div>

        {(isOperatorDialogOpen && operators) && <OperatorDialog
            operators={operators}
            onOperatorAdded={(operator: Operator) => operatorsQuery.refetch()}
            onClose={() => { setIsOperatorDialogOpen(false) }}
        />}
    </>)
}
