import { useQueries } from '@tanstack/react-query';
import { fetchProtocol, fetchProtocolPdf, fetchRecord, fetchVisualInspectionImage } from '../api/requests';
import { Protocol, Record } from '../types/sharedTypeImpl';
import useFetchOnce from './useFetchOnce';


export function useFetchProtocolOnce(
    protocolId: string | null | undefined,
    onSuccess?: (protocol: Protocol) => void,
) {
    return useFetchOnce({
        queryKey: ['protocol', protocolId],
        queryFn: async () => await fetchProtocol(protocolId!),
        enabled: protocolId != undefined,
        onSuccess,
    });
}

export function useFetchRecordOnce(
    recordId: string | null,
    onSuccess?: (record: Record) => void,
) {
    return useFetchOnce({
        queryKey: ['record', recordId],
        queryFn: async () => await fetchRecord(recordId!),
        enabled: recordId != undefined,
        onSuccess,
    });
}

export function useFetchProtocolPdfOnce(
    protocolId: string | null | undefined,
    onSuccess?: (protocolPdf: Uint8Array) => void,
) {
    return useFetchOnce({
        queryKey: ['protocolPdf', protocolId],
        queryFn: async () => await fetchProtocolPdf(protocolId!),
        enabled: protocolId != undefined,
        onSuccess,
    });
}

export function useFetchPositionImages(
    protocol: Protocol | undefined,
) {
    return useQueries({
        queries: protocol?.positions
            .map((it, index) => ({
                queryKey: ['image', index, it.imageFileName],//Use index to avoid duplicate keys
                queryFn: () => fetchVisualInspectionImage(it.imageFileName!),
                enabled: it.imageFileName !== undefined,
                refetchOnWindowFocus: false,
            }))
            ?? [],
    });
}
