// import { ObjectId } from 'mongodb';
import superjson from 'superjson';


// superjson.registerCustom<ObjectId, string>(
//     {
//         isApplicable: (value): value is ObjectId => value instanceof ObjectId,
//         serialize: (objectId) => objectId.toHexString(),
//         deserialize: (value) => new ObjectId(value),
//     },
//     'ObjectId'
// );

export const trpcTransformer = superjson;
