import { Customer } from '../../shared/types/customer';
import { trpc } from '../utils/trpc';


interface CustomerProps {
    className?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (name: string) => void;
}

export default function CustomerSelect({
    className,
    name,
    required,
    disabled,
    readOnly,
    onChange,
}: CustomerProps) {

    const { data: customers } = trpc.customers.get.useQuery(
        undefined,
        { enabled: !disabled }
    );

    const usingUnlistedCustomer = name && !customers?.find(
        (it) => it.name.toUpperCase() === name.toUpperCase()
    );
    const optionsFinal = [
        ...customers?.filter(it => it.isActive || it.name === name) ?? [],
        ...(usingUnlistedCustomer ? [{ name, isActive: true } as Customer] : [])
    ];


    return (
        <select
            className={className}
            style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
            disabled={disabled ?? false}
            required={required}
            value={name ?? ''}
            onChange={(event) => onChange?.(event.target.value)}
        >
            <option value='' disabled hidden />
            {optionsFinal?.map((it) => (
                <option key={it.name} value={it.name}>
                    {it.name}
                </option>
            ))}
        </select>
    )
}
